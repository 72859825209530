import { arrayOf, shape, object, string } from 'prop-types'
import Image from '@/components/Primitive/Image'
import React from 'react'
import SmartLink from '@/components/Primitive/SmartLink'

import styles from './SponsorGrid.module.scss'

const Sponsor = ({ sponsor }) => {
  return (
    <SmartLink
      href={sponsor.url}
      className={styles.ImageWrapper}
      target="_blank"
    >
      <Image
        className={styles.SponsorImage}
        image={sponsor.image}
        alt={sponsor.name}
      />
    </SmartLink>
  )
}

const SponsorGrid = ({ sponsors, className }) => {
  if (!sponsors) return <> </>
  return (
    <div className={className}>
      <p className={styles.Title}>sponsors</p>
      <div className={styles.Wrapper}>
        {sponsors.map((sponsor, i) => {
          return (
            !sponsor.isPartner && (
              <Sponsor sponsor={sponsor} key={`${sponsor._key}-${i}`} />
            )
          )
        })}
      </div>
    </div>
  )
}

SponsorGrid.propTypes = {
  sponsors: arrayOf(
    shape({
      url: string,
      name: string,
      image: shape({
        asset: object,
        alt: string
      })
    })
  )
}

export default SponsorGrid
