import React from 'react'
import { string, node } from 'prop-types'
import Image from '@/components/Primitive/Image'
import SplitPanel from '../SplitPanel'
import Container from '@/components/Primitive/Container'
import styles from './AboutHero.module.scss'
import classNames from 'classnames'

const AboutHero = ({ imageUrl, className, children }) => {
  return (
    <Container
      className={classNames(styles.AboutHero, className)}
      size="mediumLarge"
      center
      gutter
      spacious
      as="section"
    >
      <SplitPanel>
        <Image layout="fill" src={imageUrl} alt={'Controllers'} />
        {children}
      </SplitPanel>
    </Container>
  )
}

AboutHero.propTypes = {
  children: node,
  imageUrl: string.isRequired,
  className: string
}

export default AboutHero
