import { array } from 'prop-types'
import Container from '@/components/Primitive/Container'
import React from 'react'
import SponsorGrid from '@/components/Common/SponsorGrid'

import styles from './Sponsors.module.scss'

const Sponsors = ({ sponsors }) => {
  return (
    <Container
      className={styles.Sponsors}
      size="mediumLarge"
      center
      gutter
      spacious
      as="section"
    >
      <SponsorGrid sponsors={sponsors} />
    </Container>
  )
}

Sponsors.propTypes = {
  sponsors: array
}

export default Sponsors
