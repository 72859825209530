import React from 'react'
import { node } from 'prop-types'
import Image from '@/components/Primitive/Image'
import Container from '@/components/Primitive/Container'
import styles from './Testimonials.module.scss'
import Block from '@/components/Composition/Block'
import Icon from '@/components/Primitive/Icon'
import classNames from 'classnames'
import Type from '@/components/Primitive/Type'

const Testimonials = ({ reviews }) => {
  return (
    <Container
      className={styles.Testimonials}
      size="wide"
      center
      gutter
      spacious
      as="section"
    >
      <Container
        className={styles.TestimonialsInner}
        size="mediumLarge"
        center
        gutter
      >
        <p className={styles.SectionName}>TESTIMONIALS</p>
        <Type as="h2" size="displayLarge">
          What Our Students Are Saying:
        </Type>
        <div className={styles.CardSection}>
          {reviews.map((singleReview, idx) => {
            const { rating, image, name, review: body } = singleReview
            const ratingArray = Array(5).fill(null)
            return (
              <div className={styles.Review} key={`home-page-review-${idx}`}>
                <div className={styles.ReviewInner}>
                  <div>
                    {ratingArray.map((_, idx) => (
                      <Icon
                        className={classNames(
                          styles.Icon,
                          idx <= rating ? styles.fullStar : styles.emptyStar
                        )}
                        key={`${name}-rating`}
                        a11yText={idx <= rating ? 'Full Star' : 'Empty Star'}
                        type="star"
                        width={25}
                        height={25}
                      />
                    ))}
                    <Block body={body} />
                  </div>
                  <div className={styles.User}>
                    <Image
                      className={styles.Image}
                      image={image}
                      alt={'Reviewer‘s Photo'}
                    />
                    <p>{name}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Container>
  )
}

Testimonials.propTypes = {
  pageData: node.isRequired
}

export default Testimonials
