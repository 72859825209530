import React, { useRef } from 'react'
import Type from '@/components/Primitive/Type'

import styles from './JoinUs.module.scss'
import Container from '@/components/Primitive/Container'
import Icon from '@/components/Primitive/Icon'

const JoinUs = () => {
  const ref = useRef()
  const perks = [
    {
      title: 'Learn',
      description:
        'We teach you creative design and collaboration, encouraging imagination and bringing your ideas to life.',
      iconType: 'Suitcase'
    },
    {
      title: 'Play',
      description:
        'We connect you to other like minded friends to explore digital worlds or play around with creating costumes for cosplay.',
      iconType: 'Whiteboard'
    },
    {
      title: 'Compete',
      description:
        'Inter-location battles and cosplay showdowns.',
      iconType: 'Wrench'
    }
  ]
  return (
    <Container
      className={styles.JoinUs}
      size="mediumLarge"
      center
      gutter
      spacious
      as="section"
      ref={ref}
    >
      <p className={styles.SectionName}>JOIN US</p>
      <Type as="h2" size="titleLarge" bold>
        What you can gain?
      </Type>
      <div className={styles.Perks}>
        {perks.map((item, idx) => (
          <div className={styles.Item} key={`join-us-perk-item-${idx}`}>
            <div className={styles.IconWrapper}>
              <Icon
                a11yText={item.iconType}
                type={item.iconType.toLowerCase()}
                width={30}
                height={30}
              />
            </div>
            <p className={styles.Title}>{item.title}</p>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default JoinUs
