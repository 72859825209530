import { array, string, bool } from 'prop-types'
import BlockContent from '@/components/Primitive/BlockContent'
import Container from '@/components/Primitive/Container'
import React from 'react'
import Type from '@/components/Primitive/Type'

import styles from './Block.module.scss'
import classNames from 'classnames'

const Block = ({ body, title, as, children, spacious, className }) => {
  return (
    <Container
      className={classNames(styles.Block, className)}
      size="wide"
      center
      gutter
      spacious={spacious}
      as="section"
    >
      {title && (
        <Type as={as || 'h1'} size="display" className={styles.Title}>
          {title}
        </Type>
      )}
      {body && <BlockContent baseFontSize="base" blocks={body} />}
      {children && <div className={styles.Content}>{children}</div>}
    </Container>
  )
}

Block.propTypes = {
  body: array,
  title: string,
  className: string,
  spacious: bool
}

export default Block
